import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";

import "./styles.css";
import { LinksFunction, LoaderFunction, defer } from "@remix-run/node";
import React from "react";
import { getSession } from "./sessions";
import { getUserFromSession, transformUser } from "./api";
import invariant from "tiny-invariant";
import { getClientIp } from "./.server/utils";
import { TooltipProvider } from "./components/Tooltip";
import { Plan, getPlanFromUser } from "./lib/billing";

export type LoaderData = {
  userId: string | null;
  plan: Plan;
  ip: string;
  canonicalUrl: string;
  user: ReturnType<typeof transformUser>;
};

export const useRootLoaderData = () => {
  const data = useRouteLoaderData<LoaderData>("root");
  invariant(data, "Root loader data not found");
  return data;
};

export const loader: LoaderFunction = async ({ request }) => {
  const session = await getSession(request.headers.get("Cookie"));
  const user = await getUserFromSession(session);
  const plan = getPlanFromUser(user);
  const ip = getClientIp(request);

  return defer({
    userId: user?.id ? String(user.id) : null,
    ip,
    user: transformUser(user),
    canonicalUrl: new URL(request.url).origin + new URL(request.url).pathname,
    plan: plan,
  } satisfies LoaderData);
};

export const links: LinksFunction = () => {
  return [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@600&display=swap",
    },
  ];
};

export function Layout({
  children,
  canonicalUrl,
}: {
  children: React.ReactNode;
  canonicalUrl?: string;
}) {
  const { canonicalUrl: rootCanonicalUrl } =
    useRouteLoaderData<LoaderData>("root") || {};
  const finalCanonicalUrl = canonicalUrl || rootCanonicalUrl || "";

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="canonical" href={finalCanonicalUrl} />
        <Meta />
        <Links />
      </head>
      <body className="h-full font-body">
        <TooltipProvider delayDuration={100}>{children}</TooltipProvider>
        <ScrollRestoration />
        <Scripts />
        <script
          async
          data-domain="konbert.com"
          src="https://plausible.io/js/script.js"
        ></script>
        <script src="https://accounts.google.com/gsi/client" defer></script>
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div className="flex flex-col items-center justify-center h-screen font-sans text-center">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-xl">
          The page you&apos;re looking for doesn&apos;t exist.
        </p>
      </div>
    );
  }

  captureRemixErrorBoundaryError(error);

  return <div>Something went wrong</div>;
};
